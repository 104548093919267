import {
  Flex,
  Image,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import team1 from "../resources/team1.png";
import team2 from "../resources/team2.png";
import team3 from "../resources/team3.png";
import team4 from "../resources/team4.png";
import en from "../localization/en.json";

const teamText = en.team;

export const Team = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      flex={1}
      height="100%"
      align="center"
      justify="center"
      color="brand.teamText"
      flexDir="column"
    >
      <VStack
        mt="0.5rem"
        p="3rem"
        bg="rgba(255, 255, 255, 0.8)"
        spacing={12}
        fontSize="2xl"
        width={["90%", "unset"]}
      >
        <Text align="center" fontSize="6xl" fontWeight="600">
          {teamText.heading}
        </Text>
        <Stack
          justify="center"
          align="center"
          spacing={12}
          direction={isMobile ? "column" : "row"}
        >
          <VStack justify="center" align="center">
            <Image
              boxSize={["10rem", "20rem"]}
              borderRadius="3xl"
              src={team1}
            />
            <Text align="center" as="b" fontWeight="800" mt="0.3rem">
              {teamText.team1}
            </Text>
            <Text align="center" as="i" mt="0.3rem">
              {teamText.team1Job}
            </Text>
          </VStack>
          <VStack justify="center" align="center">
            <Image
              boxSize={["10rem", "20rem"]}
              borderRadius="3xl"
              src={team2}
            />
            <Text align="center" as="b" fontWeight="800" mt="0.3rem">
              {teamText.team2}
            </Text>
            <Text align="center" as="i" mt="0.3rem">
              {teamText.team2Job}
            </Text>
          </VStack>
          <VStack justify="center" align="center">
            <Image
              boxSize={["10rem", "20rem"]}
              borderRadius="3xl"
              src={team3}
            />
            <Text align="center" as="b" fontWeight="800" mt="0.3rem">
              {teamText.team3}
            </Text>
            <Text align="center" as="i" mt="0.3rem">
              {teamText.team3Job}
            </Text>
          </VStack>
          <VStack justify="center" align="center">
            <Image
              boxSize={["10rem", "20rem"]}
              borderRadius="3xl"
              src={team4}
            />
            <Text align="center" as="b" fontWeight="800" mt="0.3rem">
              {teamText.team4}
            </Text>
            <Text align="center" as="i" mt="0.3rem">
              {teamText.team4Job}
            </Text>
          </VStack>
        </Stack>
      </VStack>
    </Flex>
  );
};
