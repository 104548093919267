import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { Home, Rarity, About, Routes, Roadmap, FAQ, Team } from "./pages";
import { Layout } from "./components";
import { theme } from "./theme";
import { useMemo } from "react";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import "./App.css";

const network = WalletAdapterNetwork.Mainnet;

const Providers = ({ children }: { children: React.ReactNode }) => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ChakraProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Router basename={process.env.REACT_APP_BASE}>{children}</Router>
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ChakraProvider>
  );
};

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path={Routes.Home} component={Home} />
      <Route exact path={Routes.Rarity} component={Rarity} />
      <Route exact path={Routes.About} component={About} />
      <Route exact path={Routes.Roadmap} component={Roadmap} />
      <Route exact path={Routes.FAQ} component={FAQ} />
      <Route exact path={Routes.Team} component={Team} />
    </Switch>
  );
};

export const App = () => {
  return (
    <Providers>
      <Layout>
        <AppRoutes />
      </Layout>
    </Providers>
  );
};
