import {
  Button,
  VStack,
  HStack,
  IconButton,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/modal";
import { GiHamburgerMenu } from "react-icons/gi";
import en from "../localization/en.json";
import { useHistory } from "react-router";
import { Routes } from "../pages";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { NavbarStrings } from ".";

const navbar: NavbarStrings = en.navbar;

export const NavbarDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();

  return (
    <>
      <IconButton
        size="lg"
        variant="ghost"
        onClick={onOpen}
        aria-label="Navigation"
        icon={<GiHamburgerMenu />}
      />
      <Drawer size="xs" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent color="brand.drawerText">
          <DrawerCloseButton />
          <DrawerHeader bg="brand.drawerHeader">
            {navbar.brandName}
          </DrawerHeader>
          <DrawerBody bg="brand.drawerBody">
            <Flex flexDirection="column" height="100%">
              <VStack>
                {navbar.hasOwnProperty("home") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.Home);
                    }}
                  >
                    {navbar.home}
                  </Button>
                )}
                {navbar.hasOwnProperty("rarity") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.Rarity);
                    }}
                  >
                    {navbar.rarity}
                  </Button>
                )}
                {navbar.hasOwnProperty("about") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.About);
                    }}
                  >
                    {navbar.about}
                  </Button>
                )}
                {navbar.hasOwnProperty("whitepaper") && (
                  <Button variant="ghost">
                    <Link
                      to="../resources/whitepaper.pdf"
                      target="_blank"
                      download
                    >
                      {navbar.whitepaper}
                    </Link>
                  </Button>
                )}
                {navbar.hasOwnProperty("faq") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.FAQ);
                    }}
                  >
                    {navbar.faq}
                  </Button>
                )}
                {navbar.hasOwnProperty("roadmap") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.Roadmap);
                    }}
                  >
                    {navbar.roadmap}
                  </Button>
                )}
                {navbar.hasOwnProperty("team") && (
                  <Button
                    variant="ghost"
                    onClick={() => {
                      onClose();
                      history.push(Routes.Team);
                    }}
                  >
                    {navbar.team}
                  </Button>
                )}
              </VStack>
              <Spacer />
              <HStack justify="center">
                <IconButton
                  _hover={{ bg: "brand.drawerHeader" }}
                  bg="brand.drawerBody"
                  aria-label="Discord Icon"
                  icon={<FaDiscord />}
                  onClick={() => {
                    onClose();
                    window.location.href = navbar.firstSocialLink;
                  }}
                />
                <IconButton
                  _hover={{ bg: "brand.drawerHeader" }}
                  bg="brand.drawerBody"
                  aria-label="Twitter Icon"
                  icon={<FaTwitter />}
                  onClick={() => {
                    onClose();
                    window.location.href = navbar.secondSocialLink;
                  }}
                />
              </HStack>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
