import { Navbar } from ".";
import { Flex } from "@chakra-ui/react";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex minHeight="100vh" flexDirection="column">
      <Navbar>{children}</Navbar>
    </Flex>
  );
};
