import { Box, Text, Stack } from "@chakra-ui/react";
import { Metadata } from ".";

export const ItemRarities = ({
  path,
  name,
  color,
  percentage,
  total,
  stock,
  rarity,
}: Metadata) => {
  return (
    <Box p="1rem" textAlign="center" bg="brand.rarityBox">
      <Text fontWeight="800" fontSize="lg">
        {name}
      </Text>
      <Stack width="70%" margin="auto">
        <img src={require(`../${path}`).default} alt="foto" />
        <Text style={{ color: color, fontWeight: "bold" }}>{rarity}</Text>
        <Text style={{ color: color }}>{percentage.toFixed(2)}%</Text>
        <Text>{`Stock: ${stock}/${total}`}</Text>
      </Stack>
    </Box>
  );
};
