export enum Routes {
  Home = "/",
  Rarity = "/rarity",
  About = "/about",
  Roadmap = "/roadmap",
  FAQ = "/faq",
  Team = "/team",
}

export * from "./Home";
export * from "./Rarity";
export * from "./About";
export * from "./Roadmap";
export * from "./FAQ";
export * from "./Team";
