import {
  VStack,
  Text,
  Box,
  Flex,
  Center,
  Spacer,
  useBreakpointValue,
} from "@chakra-ui/react";
import en from "../localization/en.json";
import { TextCard } from "../components";

const faq = en.faq;

export const FAQ = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex flex={1} height="100%" align="center" justify="center">
      <Spacer />
      <Box
        justify="center"
        height="100%"
        width={isMobile ? "90%" : "80%"}
        bg="brand.faq"
        color="brand.faqText"
        opacity="80%"
      >
        <Center>
          <VStack align="left" textAlign="center" spacing="2rem" pb="3rem">
            <Text align="center" mt="2rem" fontSize="6xl" fontWeight="800">
              {faq.header}
            </Text>
            {Object.values(faq.questions).map((f, i) => {
              return (
                <TextCard
                  key={i}
                  heading={f.heading}
                  description={f.description}
                />
              );
            })}
          </VStack>
        </Center>
      </Box>
      <Spacer />
    </Flex>
  );
};
