import {
  Box,
  Image,
  VStack,
  Text,
  Flex,
  Button,
  Spacer,
  Center,
} from "@chakra-ui/react";
import en from "../localization/en.json";
import sleepy from "../resources/sleepy.svg";
import background from "../resources/background.png";

export const Home = () => {
  return (
    <Flex
      flex={1}
      height="100%"
      align="center"
      justify="center"
      bgImage={background}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Spacer />
      <Box justify="center" height="100%">
        <Center>
          <VStack width="100%">
            <Image boxSize="30rem" src={sleepy} />
            <Box>
              <a href="https://mint.sleepydragons.io">
                <Button
                  color="white"
                  mt="-15rem"
                  p="2rem"
                  size="lg"
                  borderRadius="full"
                  _hover={{ borderColor: "brand.mintBorder" }}
                  boxShadow="base"
                  bg="brand.candyMachine"
                  border="4px solid transparent"
                >
                  <Text fontSize="3xl">GO TO MINT</Text>
                </Button>
              </a>
            </Box>
          </VStack>
        </Center>
      </Box>
      <Spacer />
    </Flex>
  );
};
