import {
  Image,
  Text,
  Flex,
  Button,
  HStack,
  Spacer,
  IconButton,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import { useHistory } from "react-router";
import en from "../localization/en.json";
import { Routes } from "../pages";
import logo from "../resources/logo.svg";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { NavbarDrawer } from "./NavbarDrawer";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { Link } from "react-router-dom";
import { theme } from "../theme";

export interface NavbarStrings {
  brandName?: string;
  home?: string;
  rarity?: string;
  about?: string;
  whitepaper?: string;
  faq?: string;
  roadmap?: string;
  team?: string;
  firstSocialLink: string;
  secondSocialLink: string;
}

const navbar: NavbarStrings = en.navbar;

export const Navbar = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();
  const wallet = useAnchorWallet();

  const brand = theme.colors.brand;

  return (
    <>
      <Flex
        bg="brand.navbar"
        align="center"
        justify="space-between"
        color="brand.navbarText"
      >
        <HStack w={isMobile ? "80%" : "33%"}>
          <Box
            as="button"
            onClick={() => {
              history.push(Routes.Home);
            }}
          >
            <Image borderRadius="full" boxSize="4rem" src={logo} p="0.4rem" />
          </Box>
          <Text fontWeight="bold" color="brand.navbarBrand" fontSize="2xl">
            {navbar.brandName}
          </Text>
        </HStack>
        {isMobile ? (
          <NavbarDrawer />
        ) : (
          <>
            <HStack w="33%" justify="center" spacing="2rem">
              {navbar.hasOwnProperty("home") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.Home);
                  }}
                >
                  {navbar.home}
                </Button>
              )}
              {navbar.hasOwnProperty("rarity") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.Rarity);
                  }}
                >
                  {navbar.rarity}
                </Button>
              )}
              {navbar.hasOwnProperty("about") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.About);
                  }}
                >
                  {navbar.about}
                </Button>
              )}
              {navbar.hasOwnProperty("whitepaper") && (
                <Button variant="navbar">
                  <Link
                    to="../resources/whitepaper.pdf"
                    target="_blank"
                    download
                  >
                    {navbar.whitepaper}
                  </Link>
                </Button>
              )}
              {navbar.hasOwnProperty("faq") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.FAQ);
                  }}
                >
                  {navbar.faq}
                </Button>
              )}
              {navbar.hasOwnProperty("roadmap") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.Roadmap);
                  }}
                >
                  {navbar.roadmap}
                </Button>
              )}
              {navbar.hasOwnProperty("team") && (
                <Button
                  variant="navbar"
                  onClick={() => {
                    history.push(Routes.Team);
                  }}
                >
                  {navbar.team}
                </Button>
              )}
            </HStack>
            <Spacer />
            <HStack justify="center" pr="0.4rem" spacing="1rem">
              <IconButton
                bg="brand.navbar"
                _hover={{ bg: "brand.navbar" }}
                aria-label="Discord Icon"
                icon={<FaDiscord />}
                onClick={() => {
                  window.location.href = navbar.firstSocialLink;
                }}
              />
              <IconButton
                bg="brand.navbar"
                _hover={{ bg: "brand.navbar" }}
                aria-label="Twitter Icon"
                icon={<FaTwitter />}
                onClick={() => {
                  window.location.href = navbar.secondSocialLink;
                }}
              />
            </HStack>
          </>
        )}
      </Flex>
      {children}
    </>
  );
};
