import { Flex } from "@chakra-ui/layout";
import { useState } from "react";
import { useSpring, animated } from "react-spring";

interface Props {
  text: string;
}

export const TextScroller = ({ text }: Props) => {
  const [key, setKey] = useState(2);

  const scrolling = useSpring({
    from: { transform: "translate(-60%,0)" },
    to: { transform: "translate(60%,0)" },
    config: { duration: 100000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <Flex
      fontSize="2xl"
      minHeight="5rem"
      bg="brand.scroller"
      width="100%"
      maxWidth="100%"
      align="center"
      color="brand.scrollerText"
    >
      <div style={{ whiteSpace: "nowrap", overflow: "hidden" }} key={key}>
        <animated.div style={scrolling}>{text}</animated.div>
      </div>
    </Flex>
  );
};
