import { Flex, Text } from "@chakra-ui/react";
import en from "../localization/en.json";
import { ListRarities } from "../components";

export const Rarity = () => {
  const releaseDate = process.env.REACT_APP_CANDY_START_DATE
    ? parseInt(process.env.REACT_APP_CANDY_START_DATE)
    : 4102444800000;

  return (
    <Flex
      direction="column"
      height="100%"
      width="100%"
      flex={1}
      bg="brand.rarity"
      textAlign="center"
      fontSize="3xl"
      fontWeight="800"
      align="center"
      color="brand.rarityText"
    >
      <Text mt="2rem">{en.rarity.comingSoon}</Text>
      {/* {Date.now() < releaseDate ? (
        <Text mt="2rem">{en.rarity.comingSoon}</Text>
      ) : (
        <ListRarities />
      )} */}
    </Flex>
  );
};
