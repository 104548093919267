import { extendTheme } from "@chakra-ui/react";
import "@fontsource/open-sans";
import background from "../resources/blurred.png";

export const theme = extendTheme({
  fonts: {
    heading: "Open Sans",
    body: "Open Sans",
  },
  styles: {
    global: {
      body: {
        bgImage: `url(${background})`,
        bgPosition: "center",
        bgRepeat: "no-repeat",
        bgSize: "cover",
      },
    },
  },
  colors: {
    brand: {
      navbar: "#FFFFFF",
      navbarText: "#000000",
      navbarBrand: "#000000",
      navbarWallet: "#cdcdcd",

      drawerBody: "#ffb154",
      drawerHeader: "#474e7f",
      drawerText: "#FFFFFF",

      home: "#261922",
      homeText: "#FFFFFF",

      candyMachine: "#ffbb6c",
      modal: "#FFFFFF",
      modalText: "#000000",
      mintBorder: "#FFFFFF",

      about: "#FFFFFF",
      aboutText: "#000000",

      roadmap: "#261922",
      roadmapText: "#FFFFFF",

      rarity: "#ffb154",
      rarityText: "#000000",
      rarityBox: "#FFFFFF",

      faq: "#FFFFFF",
      faqText: "#000000",

      team: "#261922",
      teamText: "#000000",
    },
  },
  components: {
    Button: {
      variants: {
        navbar: {
          _hover: {
            bg: "brand.navbar",
            opacity: "0.9",
            transform: "scale(1.03)",
          },
        },
        homeSocial: {
          boxShadow: "lg",
          borderRadius: "md",
          border: "2px",
          p: "1.5rem",
          borderColor: "brand.homeSocialBorder",
          color: "brand.homeSocialText",
          _hover: {
            transform: "scale(1.03)",
            borderColor: "brand.homeSocialHover",
          },
        },
      },
    },
  },
});
