import { Text, VStack, Image, Center } from "@chakra-ui/react";
import en from "../localization/en.json";
import banner from "../resources/banner.png";

const about = en.about;

export const About = () => {
  const aboutUs = about.aboutUs;
  const distribution = about.distribution;
  const aboutUsSplit = aboutUs.split("\n");
  const distributionSplit = distribution.split("\n");

  return (
    <Center>
      <VStack pb="1rem" spacing={12}>
        <Image src={banner} width="100%" />
        <VStack
          p="1rem"
          textAlign="center"
          width="80%"
          color="brand.aboutText"
          bg="brand.about"
          spacing={6}
          opacity="80%"
        >
          <Text fontSize="4xl" fontWeight="800">
            {about.heading}
          </Text>
          {aboutUsSplit.map((a) => (
            <Text key="a" fontSize="2xl" fontWeight="500">
              {a}
            </Text>
          ))}
          <Text fontSize="4xl" fontWeight="800">
            {about.distributionHeading}
          </Text>
          {distributionSplit.map((a) => (
            <Text key="a" fontSize="2xl" fontWeight="500">
              {a}
            </Text>
          ))}
        </VStack>
      </VStack>
    </Center>
  );
};
