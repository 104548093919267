import { Box, Text, VStack } from "@chakra-ui/react";

interface Props {
  number?: string;
  heading?: string;
  description?: string;
}

export const TextCard = ({ number, heading, description }: Props) => {
  const descriptionSplit = description?.split("\n");

  return (
    <Box>
      <VStack>
        {number && (
          <Text fontSize="3xl" fontWeight="800">
            {number}
          </Text>
        )}
        {heading && (
          <Text fontSize="3xl" fontWeight="800">
            {heading}
          </Text>
        )}
        {descriptionSplit &&
          descriptionSplit.map((a, i) => (
            <Text
              key={i}
              fontSize="2xl"
              fontWeight={a.match(/^\d/) ? 800 : 500}
            >
              {a}
            </Text>
          ))}
      </VStack>
    </Box>
  );
};
