import { Flex, Image, useBreakpointValue } from "@chakra-ui/react";
import roadmap from "../resources/roadmap.png";
import mobileroadmap from "../resources/mobileroadmap.png";

export const Roadmap = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex
      justify="center"
      align="center"
      flex={1}
      bg="#FFFFFF"
      // bgPosition="center"
      // bgRepeat="no-repeat"
      // bgSize="cover"
    >
      <Image
        borderRadius={isMobile ? "unset" : "3xl"}
        boxShadow="lg"
        src={isMobile ? mobileroadmap : roadmap}
      />
    </Flex>
  );
};
