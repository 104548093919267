import { CategoryRarities } from "./CategoryRarities";
import data from "../raritiesToReactMap.json";

export const ListRarities = () => {
  return (
    <>
      {data.map((item) => {
        return <CategoryRarities key={item.attribute} {...item} />;
      })}
    </>
  );
};
