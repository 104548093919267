import { Stack, SimpleGrid, Text } from "@chakra-ui/react";
import { ItemRarities } from "./ItemRarities";

interface Category {
  attribute: string;
  items: Metadata[];
}

export interface Metadata {
  name: string;
  stock: number;
  total: number;
  percentage: number;
  rarity: string;
  color: string;
  path: string;
}

export const CategoryRarities = ({ attribute, items }: Category) => {
  return (
    <Stack align="center">
      <Text mt="2rem" textAlign="center" size="lg">
        {attribute}
      </Text>
      <SimpleGrid width="95%" columns={[1, 5]} gap="2rem">
        {items.map((item) => {
          return <ItemRarities key={item.name} {...item} />;
        })}
      </SimpleGrid>
    </Stack>
  );
};
